body {
	margin: 0;
	font-family: "Open Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	background: #F0F0F0 !important;
}
@font-face{
    font-family:"GothamBook";
    src: local("GothamBook"), url("./assets/fonts/GothamBook.ttf") format("truetype");
}
@font-face {
	font-family: "PlusJakartaSansBold";
	src: local("PlusJakartaSansBold"), url("./assets/fonts/PlusJakartaSans-Bold.ttf") format("truetype");
	font-weight: bold;
}
@font-face {
	font-family: "PlusJakartaSansRegular";
	src: local("PlusJakartaSansRegular"), url("./assets/fonts/PlusJakartaSans-Regular.ttf") format("truetype");
	font-weight: normal;
}
@font-face{
    font-family:"GothamBook";
    src:X local("GothamBook"), url("./assets/fonts/GothamBook.ttf") format("truetype");
}
@font-face{
    font-family:"GothamBold";
    src:X local("GothamBold"), url("./assets/fonts/GothamBold.ttf") format("truetype");
}
@font-face {
	font-family: "ZillaSlabBold";
	src: local("ZillaSlabBold"), url("./assets/fonts/ZillaSlabBold.ttf") format("truetype");
	font-weight: normal;
}
/* width */
::-webkit-scrollbar {
	width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
	background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: white;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.ml-1 {
	margin-left: 0em !important;
}
div[data-test="datatable-search"] {
	padding-left: 0px;
}
.mdb-datatable-filter > input {
	width: 290px !important;
	height: 45px;
	border: 1px solid #ececec !important;
	border-radius: 5px !important;
	background-color: #ececec;
}
.MuiOutlinedInput-notchedOutline {
	border-color: rgb(0 0 0 / 0%) !important;
}
.MuiTab-wrapper {
	display: block !important;
	font-size: 14px;
	font-weight: 600;
	text-transform: capitalize;
}
.MuiOutlinedInput-input {
	font-size: 14px !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "ZillaSlabBold" !important;
	/* font-family: "PlusJakartaSansBold" !important; */
}
p,
div {
	/* font-family: "GothamBook" !important; */
	font-family: "PlusJakartaSansRegular" !important;
}
.active_class div {
	transition: 0.5s;
	/* background-color: lightgray !important; */
	background-color: #0391b4 !important;
	font-weight: 600;
}
.Mui-selected {
	color: white !important;
}
.MuiTabs-indicator {
	background-color: white !important;
}
.MuiListItem-gutters {
	padding-left: 0px !important;
	padding-right: 0px !important;
	/* justify-content: center !important; */
}
.MuiListItemIcon-root {
	justify-content: center !important;
	background-color: #F0F0F0 !important;;
}
#round {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 5000;
	background: rgb(190 190 190 / 50%) url(./assets//images/loader.gif) center center no-repeat;
	opacity: 1;
}
.mdb-dataTable-head {
	background: white;

}
#header div {
	display: flex;
	justify-content: space-between;
}
.menuBar {
	opacity: 0;
	display: none;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
	#header {
		width: 100%;
		z-index: 5;
	}
	#list {
		position: relative;
	}
	.menuBar {
		opacity: 1;
		display: block;
	}
}

@media screen and (min-width: 0px) and (max-width: 767px) {
	#drawer {
		position: absolute;
	}
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: none !important;
}
.MuiInput-underline:before {
	border-bottom: none !important;
}

@media screen and (max-width: 500px) {
	.drawerClose {
		width: 64px;
		position: absolute;
		display: none;
		background-color: #046982

	}
}

/* @media screen and (min-width: 390px) and (max-width:500px){
  .paper{
    width:80%;
    }
} */

input[type="file"] {
	/* background-color: #54a7e0;
  color:white;
  border: 1px solid #54a7e0;
  padding:0.4rem;
  border-radius: 5px;
  margin-top: 0.6rem;
  width:100%; */
}
/*************************/
/* mdb checkbox slided design */
.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
  }

  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }

  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
  }

  .slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
  }

  input:checked + .slider {
	background-color: #00A850;
  }


  input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
	border-radius: 34px;
  }

  .slider.round:before {
	border-radius: 50%;
  }

/**********************/
.MuiMenu-paper {
	max-height: unset !important;
	background-color: #046982;
}
label[for='file']{
	min-width: 100%;
	padding:3rem;
 	border:2px dashed #00A850;
  	background-color: #ECF8F2;
}
.file-types{
	margin-left:-1rem
}
.MuiSelect-icon{background-image: url(./assets/svg/down.svg);margin-right: 1rem;}
path[d='M7 10l5 5 5-5z']{display:none;}
.deleteIcon{
	background-image: url(./assets/svg/close.svg);
}
path[d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z']{display: none;}
.featuredImage{
	width: 100%; height: 100%; border-radius: 1rem;
}
.defaultFeaturedImage{
	width: 24px;
	position:relative;
	top:26%;left:36%;
}
.peopleIcon{background-image: url(./assets/svg/people_alt.svg);}
path[d='M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z']{display: none;}
.liveAssetIcon{background-image: url(./assets/svg/mms.svg);}
path[d='M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z']{display: none;}
.profileIcon{
	background-image: url(./assets/svg/person_outline.svg);
}
path[d='M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z']{display: none;}
.editIcon{
	background-image: url(./assets/svg/pencil-edit.svg);
}
path[d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z']{display: none;}
.addNewIcon{
	background-image: url(./assets/svg/plus.svg);
}
.addNewIconBlack{
	background-image: url(./assets/svg/plus-black.svg);
}
path[d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z']{display: none;}
button{border-radius: 12px !important;}

/* MDB Table Custom style*/
:first-child:before {
    left: 1.6rem !important;
}
table.table thead th:first-child{padding-left: 3rem !important;}
table {
	overflow-x: scroll;
	width: 100%;
}
.table {
	margin-top: 40px;
	border-radius: 1rem;
}
.mdb-dataTables_paginate {
	display: none;
}
.mdb-dataTable{
	background-color: #ffffff;
	/* margin-top:1rem; */
}
table.mdb-dataTable thead, table thead.mdb-dataTable-head{
	/* border-radius: 1rem; */
	background: none;
}
/* .mdb-dataTable-head{background: '';} */
table.table td:first-child{padding-left: 3rem;}
#destinations table.table td:nth-last-child(2),
#trails table.table td:nth-last-child(2),
#liveassets table.table td:nth-last-child(2),
#legends table.table td:nth-last-child(2),
#links table.table td:nth-last-child(2),
#assetcategories table.table td:nth-last-child(2)
{width:6.4rem}

#assetcategories table.table td:nth-last-child(3),
#legends table.table td:nth-last-child(3)
{width:3.6rem}

/* table.table td:last-child{width:4rem; padding-right:3rem;} */

#destinations table.table td:last-child,
#trails table.table td:last-child,
#liveassets table.table td:last-child,
#links table.table td:last-child,
#assetcategories table.table td:last-child,
#legends table.table td:last-child
{width:4rem; padding-right:3rem;}
/* table.table tr:focus-visible{background-color: #F0F0F0;border-radius: 12px;} */
/* MDB Table Custom style end*/

path[d='M5.33317 6.66667H22.6665V16H25.3332V6.66667C25.3332 5.196 24.1372 4 22.6665 4H5.33317C3.8625 4 2.6665 5.196 2.6665 6.66667V22.6667C2.6665 24.1373 3.8625 25.3333 5.33317 25.3333H15.9998V22.6667H5.33317V6.66667Z']{display: none;}
path[d='M10.6665 14.6667L6.6665 20H21.3332L15.9998 12L11.9998 17.3333L10.6665 14.6667Z']{display: none;}
path[d='M25.3332 18.6667H22.6665V22.6667H18.6665V25.3333H22.6665V29.3333H25.3332V25.3333H29.3332V22.6667H25.3332V18.6667Z']{display: none;}
.MuiList-padding{
	padding:0;
	margin:0;
	/* border:1px solid #DDDDDE; */
	border-radius:12px;
}
.MuiPaper-elevation8{
border-radius:12px !important}

.MuiPopover-paper {
	max-height: 60vh !important;
}
.Muilist-root option:first-child {
	margin-top: 0 !important;
}
.mapSidebar {
	background-color: rgba(35, 55, 75, 0.9);
	color: #fff;
	padding: 6px 12px;
	font-family: monospace;
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	margin: 12px;
	border-radius: 4px;
}

.MuiSelect-outlined.MuiSelect-outlined {
	padding-right: 62px !important;
}